import cx from 'classnames'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { GiHamburgerMenu } from 'react-icons/gi'
import Logo from '../images/logo-yellow-white.png'
import styles from './navigation.module.css'

export default function Navigation({ location }) {
  const [isOpen, setOpen] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  const isHome = location.pathname === '/'

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/css?family=Hepta+Slab:300,400,700&display=swap"
        rel="stylesheet"
      />
      <nav
        className={isOpen ? styles.mobileNavOpen : styles.mobileNav}
        role="navigation"
      >
        <Link to="/recipes" className={styles.mobileNavItem}>
          recipes
        </Link>
        <Link to="/issues" className={styles.mobileNavItem}>
          issues
        </Link>
        <Link to="/about" className={styles.mobileNavItem}>
          about
        </Link>
      </nav>
      <nav
        role="navigation"
        className={cx(styles.navigation, {
          [styles.floating]: isHome,
          [styles.showing]: scrollTop > 200,
        })}
      >
        <button className={styles.dropdown} onClick={() => setOpen(!isOpen)}>
          {!isOpen && <GiHamburgerMenu color="white" size={36} />}
          {isOpen && <FaTimes color="white" size={36} />}
        </button>
        <div className={styles.contents}>
          <Link to="/" className={styles.home}>
            <img
              src={Logo}
              alt="Journey East"
              className={styles.journeyEastLogo}
            />
          </Link>
          {/* <Link to="/map" className={styles.navigationItem}>Map</Link> */}
          <Link to="/recipes" className={styles.navigationItem}>
            recipes
          </Link>
          <Link to="/issues" className={styles.navigationItem}>
            issues
          </Link>
          <Link to="/about" className={styles.navigationItem}>
            about
          </Link>
        </div>
      </nav>
    </React.Fragment>
  )
}
